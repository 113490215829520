.work {
	max-width: 60em;
	margin: 0 auto;

    &-image {
        max-width: 30%;
        float: left;
        // margin: 0 auto;
        // display: block;

        &--bordered {
            box-shadow: 0px 1px 5px #999
        }
    }

    @at-root .post figure:not(.highlight),
    figure:not(.highlight) {
        margin: 1em 0;
        padding: 1.5em;
        background-color: #eee;
        clear: both;
        text-align: center;

        figcaption {
            text-align: left;
        }
    }
}

@media only screen and (min-width: 40em){ 
    .work-image {
        // max-width: 80%;
    }
}