.about {
	max-width: 60em;
	margin: 0 auto;

    &--photo-container {
        max-width: 18em;
    }

    p {
        margin-top: 0;
    }
}

@media only screen and (min-width: 40em){ 
    .about {
        &--photo-container {
            width: 40%;
            text-align: center;
        }

        &--description {
            width: 60%;
        }
    }
}
